<template>
<div class="about-page w-full h-screen">
  <div class="begin">
  <GmapMap
    :center='center'
    :zoom='12'
    class="map"
  >
    <div
      :key="index"
      v-for="(m, index) in markers"
    >
      <GmapMarker
        :position="m.position"
        @click="openWindow(m, index)"
      >
        <gmap-info-window
          @closeclick="position = -1"
          :opened="position === index"
          :position="center"
          :options="{
          pixelOffset: {
            width: 0,
            height: -35
          }
        }"
        >
          <div class="font-bold text-center text-black">{{m.label}}</div>
          <div class="mt-2 text-center">{{m.description}}</div>
          <div class="flex justify-center">
            <div class="mt-2 text-center button" @click="ouvrir(m)">
              Ouvrir avec google maps
            </div>
          </div>
        </gmap-info-window>
      </GmapMarker>
    </div>
  </GmapMap>
  </div>
</div>
</template>

<script>
import agence from '@/components/cedro/jsonWord/agences.json'

export default {
  name: 'index',
  components: {
  },
  data () {
    return {
      center: { lat: 45.508, lng: -73.587 },
      currentPlace: null,
      markers: [{
        lat: 45.508,
        lng: -73.587
      }],
      places: ['cotonou'],
      info_marker: null,
      infowindow: { lat: 0, lng: 0 },
      position: -1
    }
  },
  created () {
    // this.geolocate()
  },
  mounted () {
    this.addMarker()
  },
  methods: {
    ouvrir (index) {
      window.open('http://maps.google.com?q=' + index.position.lat + ',' + index.position.lng, '_blank')
    },

    openWindow (index, i) {
      this.position = i
      this.center = index.position
    },

    addMarker () {
      for (const item in agence) {
        const marker = {
          lat: agence[item].latitude,
          lng: agence[item].longitude
        }
        this.markers.push({ position: marker, label: agence[item].name, description: agence[item].details })
        this.places.push(agence[item])
        this.center = marker
      }
    },

    geolocate () {
      navigator.geolocation.getCurrentPosition(position => {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/style/sass/variables";

.about-page{
  width: 100%;
  height: 100%;
  margin-right: auto;
  margin-left: auto;
  font-family: $font-default;
}

  .titre{
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 64px;
    text-align: center;
    color: #000000;
  }
  .num{
    font-weight: bold;
    font-size: 64px;
    line-height: 34px;
    color: rgba(2, 105, 173, 0.1);
  }
  .sous{
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    color: #49BF7C;
  }
  .button{
    background-color: $base-color;
    border: none;
    color: white;
  }

  .map /deep/{
      .vue-map {
        height: 700px !important;
      }
  }
.begin {
  height: 75vh;
}
.gmaps-map {
  position: sticky;
  width: 100%;
  height: 100%;
}
@media only screen and (max-width: 700px) {
  .about-page{
    width: 100%;
    height: 100%;
  }
  .titre{
    font-size: 28px;
    line-height: 37px;
  }
  .sous{
    font-size: 18px;
    line-height: 24px;
  }
  .num{
    font-size: 48.4571px;
    line-height: 25px;
  }
  .web{
    display: none;
  }
  .mobile{
    display: block;
    width: 100%;
  }
}
</style>
